
import { defineComponent, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  name: 'RegisterResult',
  setup() {
    const route = useRoute();
    const router = useRouter();
    if (!route.params) {
      router.push({
        name: 'login',
      });
    }
    const email = computed(() => `你的账户：${route.params.email} 注册成功`);

    const goHomeHandle = () => {
      router.push({
        name: 'login',
      });
    };
    return {
      email,
      goHomeHandle,
    };
  },
});
